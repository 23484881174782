.custom-tabs {
    width: 100%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .tab-header {
    display: flex;
    flex-wrap: wrap; /* Allows tabs to wrap onto the next line */
    justify-content: flex-start; /* Align tabs to the left */
    gap: 5px; /* Optional: Adds spacing between tabs */
    border-bottom: 2px solid #ccc;
    padding: 5px;
  }
  
  .tab-button {
    flex: 1 1 auto; /* Ensures the buttons resize as needed */
    padding: 10px 10px;
    text-align: center;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    color: #555;
    transition: color 0.3s, border-bottom 0.3s;
  }
  
  .tab-label.active {
    color: #007bff;
    border-bottom: 3px solid #007bff;
  }
  
  .tab-label {
    color: #555;
    padding-bottom: 7px;
  }
  .tab-button:hover {
    color: #0056b3;
  }
  
  .tab-content {
    padding: 20px;
    font-size: 14px;
    color: #333;
    border-top: none;
  }
  