/* .deksptopHeaderMenus{
    text-align: right;
    padding-right: 38px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 6px;
}
.mobileHeaderMenu{
    text-align: right;
    padding-right: 23px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;
}
.mainHeaderDiv{
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 70px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.subHeaderDiv{
    background-image: linear-gradient(to right, #fafafa 0%, #ffabab 50%, #feada6 71%, #feada6 100%);
    border-radius: 53px;
    z-index: 1000;
    box-shadow: rgb(255, 247, 142) 0px 0px 9px 0px;
    width: 70%;
    height: 70px;
}
.logoImg{
    height: 70px;
    margin-left: 44px;
    margin-top: 13px;
}
.drawerImgLogo{
    height: 73px;
    margin-top: 20px;
    margin-bottom: 20px;
    
}

.drawerImgLogoDiv{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;    
}
@media (min-width: 768px) {
   .deksptopHeaderMenus{
    display: flex;
   }
   .subHeaderDiv{
    width: 60%;
    height: 70px;
   }
   .logoImg{
     height: 70px;
     margin-top: 13px;
   }
   .mainHeaderDiv{
    margin-top: 20px;
   }
   .mobileHeaderMenu{
    display: none;
   }
  }
@media (max-width: 768px) {
    .deksptopHeaderMenus{
        display: none;
    }
    .subHeaderDiv{
        width: 90%;
        height: 70px;
    }
    .logoImg{
        height: 50px;
        margin-top: 8px;
      }
    .mainHeaderDiv{
        margin-top:0px;
    }  
    .mobileHeaderMenu{
        display: flex;
    }
}   */

.linktag{
    text-decoration: initial;
    color: black;
    font-family: cursive;
}