/* {Unique css} */
.dc-table-container {
    max-width: 100%;
    margin: auto;
    /* border: 1px solid #ccc; */
    /* padding: 16px; */
    /* background-color: #f9f9f9; */
}

.dc-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-size: 13px;
}

.dc-table th,
.dc-table td {
    border: 1px solid #afc17b;
    text-align: center;
    padding: 2px;
}

.dc-table th {
    background-color: #afc17b;; /* Light green for better readability */
    font-weight: bold;
    color: white;
    height: 30px;
}

.dc-table-title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.dc-footer {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-top: 17px;
    font-weight: bold;
}
.dccondition-footer {
    font-size: 13px;
    margin-top: -15px;
}

/* {soham css} */

.dcsoham-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-size: 13px;
}

.dcsoham-table th,
.dcsoham-table td {
    border: 1px solid rgb(166, 166, 192);   
    text-align: center;
    padding: 2px;
}

.dcsoham-table th {
    background-color: rgb(166, 166, 192); /* Light green for better readability */
    font-weight: bold;
    color: white;
    height: 30px;
}

.dcsoham-table-title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}